<template>
  <div
    v-if="readonly"
    class="w-full cursor-pointer rounded bg-white border hover:bg-secondary-50 active:bg-secondary-100 border-secondary-300 text-secondary-900 p-3 flex items-center justify-between"
    @click.stop.prevent="openInNewTab">
    <span class="text-secondary-900 text-sm truncate">
      {{ fileInstance.title?.length ? fileInstance.title : fileInstance.url }}
    </span>
    <div class="flex gap-2">
      <button
        :title="$t('course_settings.open_link')"
        class="h-8 w-8 flex items-center justify-center rounded-[0.625rem] border-1 border-secondary-300 bg-secondary-100 transition-colors hover:text-secondary-975">
        <ArrowUpLeft size="16" class="" />
      </button>
    </div>
  </div>
  <div v-else>
    <div
      class="add-links overflow-hidden transition-[height] duration-500 flex gap-2 justify-between items-center">
      <div class="flex gap-2 grow h-14">
        <PVInputGroup :class="{ 'p-invalid': state.urlHasChanged && !isValid }">
          <PVInputText
            v-model="fileInstance.url"
            class="w-1/2 h-full !pe-0 truncate"
            type="text"
            :title="fileInstance.url"
            :invalid="state.urlHasChanged && !isValid"
            :placeholder="$t('course_settings.new_link')"
            @input="state.urlHasChanged = true"
            @keydown.enter="isValid && saveLink('title', 'urlHasChanged')"/>

          <PVInputGroupAddon>
            <div class="flex items-center gap-1 h-full">
              <CircleArrowLeft
                v-if="isValid && state.urlHasChanged"
                size="16"
                class="stroke-secondary-900 cursor-pointer"
                @click="saveLink('title', 'urlHasChanged')"/>
              <CircleX
                v-if="fileInstance.url"
                size="16"
                class="stroke-secondary-900 cursor-pointer"
                @click="fileInstance.url = ''"/>
            </div>
          </PVInputGroupAddon>
        </PVInputGroup>

        <PVInputGroup>
          <PVInputText
            v-model="fileInstance.title"
            class="w-1/2 h-full truncate"
            :class="{
              '!pe-0': fileInstance.url && state.titleHasChanged,
            }"
            :title="fileInstance.title"
            type="text"
            :disabled="!isValid || state.urlHasChanged"
            :placeholder="$t('course_settings.link_description')"
            @input="state.titleHasChanged = true"
            @keydown.enter="saveLink('url', 'titleHasChanged')"/>

          <PVInputGroupAddon>
            <div class="flex gap-1">
              <CircleArrowLeft
                v-if="
                  fileInstance.url &&
                  fileInstance.title?.trim().length &&
                  state.titleHasChanged
                "
                size="16"
                class="stroke-secondary-900 cursor-pointer"
                @click="saveLink('url', 'titleHasChanged')"/>
              <CircleX
                v-if="fileInstance.title"
                size="16"
                class="stroke-secondary-900 cursor-pointer"
                @click="
                  () => {
                    fileInstance.title = '';
                    saveLink('url', 'titleHasChanged');
                  }
                "/>
            </div>
          </PVInputGroupAddon>
        </PVInputGroup>
      </div>
      <button
        class="cursor-pointer h-8 w-8 flex items-center justify-center rounded-[0.625rem] bg-red-50 border-1 border-secondary-300 hover:bg-red-100 transition-colors hover:text-secondary-975"
        @click.stop="emit('delete-file', file)">
        <X size="16" />
      </button>
    </div>
    <span v-if="state.urlHasChanged && !isValid" class="text-xs text-red-500">
      {{ $t('course_settings.invalid_url') }}
    </span>
  </div>
</template>

<script setup lang="ts">
import PVInputGroupAddon from 'primevue/inputgroupaddon';
import PVInputText from 'primevue/inputtext';
import { CircleArrowLeft, CircleX, ArrowUpLeft, X } from 'lucide-vue-next';
import PVInputGroup from 'primevue/inputgroup';
import * as linkify from 'linkifyjs';
import { computed, onUnmounted, reactive } from 'vue';
import { useVModel } from '@vueuse/core';

const emit = defineEmits(['delete-file', 'update-file']);
const props = defineProps({
  file: {
    type: Object,
    required: true,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

const state = reactive({
  urlHasChanged: false,
  titleHasChanged: false,
});

const fileInstance = useVModel(props, 'file');
let localFile = { ...props.file };
const isValid = computed(
  () => fileInstance.value.url && linkify.test(fileInstance.value.url),
);

const openInNewTab = () => {
  const url = fileInstance.value.url.includes('://')
    ? fileInstance.value.url
    : `https://${fileInstance.value.url}`;
  window.open(url, '_blank', 'noopener,noreferrer');
};

const saveLink = async (keyToUnsave, unsetKey) => {
  emit('update-file', fileInstance.value, keyToUnsave);
  delete fileInstance.value['new'];
  localFile = {
    ...fileInstance.value,
    [keyToUnsave]: localFile[keyToUnsave],
  };

  state[unsetKey] = false;
};

onUnmounted(() => {
  const { url, new: isNew } = fileInstance.value;
  const { urlHasChanged, titleHasChanged } = state;

  // If the file is new or has no URL, delete it and return early
  if (!url?.trim() || isNew) {
    emit('delete-file', fileInstance.value);
    return;
  }

  // Collect fields that need updating
  const updatedFields = [];
  if (urlHasChanged) updatedFields.push('title');
  if (titleHasChanged) updatedFields.push('url');

  // Emit update-file event for each changed field
  updatedFields.forEach(field => emit('update-file', { ...localFile }, field));
});
</script>
