<template>
  <PVDrawer
    v-model:visible="visible"
    :pt="{
      title: 'font-simplerRegular text-sm',
    }"
    class="!w-[40%]"
    :header="$t('course_page.files_library')">
    <div class="flex flex-col h-full gap-3 px-5 py-4">
      <div class="flex gap-2 items-start w-full">
        <BaseInput
          v-model="search"
          type="search"
          :placeholder="$t('course_page.search_file')">
          <template #prefix>
            <SearchIcon :size="16" class="search stroke-secondary-600" />
          </template>
        </BaseInput>
        <PVSelect
          v-model="selectedLesson"
          :options="lessonOptions"
          option-label="title"
          :placeholder="$t('course_page.select_lesson')"
          class="w-[134px] h-full"/>
      </div>

      <div class="flex flex-1 h-full pt-2">
        <ScrollableContainer>
          <div
            v-if="noFilesToDisplay && readonly"
            class="flex flex-col items-center gap-3 w-full">
            <img
              v-if="noFilesToDisplay && !noFilesAfterSearch"
              :src="noInitialFiles"
              alt="No files found"
              class="mx-auto w-[15rem]"/>
            <FilesNotFound v-else class="mx-auto w-[20rem]" />
            <span>
              {{
                !noFilesAfterSearch
                  ? t('course_settings.no_initial_files')
                  : t('course_settings.no_files_message')
              }}
            </span>
          </div>
          <div v-else>
            <div>
              <div v-if="selectedLesson && selectedLesson.id">
                <div
                  v-if="isEmpty(lessonFiles) && readonly"
                  class="flex flex-col items-center gap-3 w-full">
                  <FilesNotFound class="mx-auto w-[15rem]" />
                </div>
                <div v-else class="flex flex-col gap-2 ml-4">
                  <div class="text-secondary-900 text-sm font-simplerBold">
                    {{ selectedLesson.title }}
                  </div>
                  <filesUpload
                    :files="lessonFiles"
                    :lesson-id="selectedLesson.id"
                    :owner-id="user.id"
                    :readonly="readonly"
                    @file-added="handleFileAdded"
                    @delete-file="handleRemoveFile"
                    @update-file="handleUpdateFile"/>
                </div>
              </div>
              <div v-else class="flex flex-col items-center gap-4">
                <div class="flex flex-col gap-2 w-full">
                  <span
                    v-if="courseFiles.length || !readonly"
                    class="text-secondary-975 text-sm">
                    {{ 'כללי לקורס' }}
                  </span>
                  <filesUpload
                    class="px-4"
                    :files="courseFiles"
                    :owner-id="user.id"
                    :readonly="readonly"
                    @file-added="handleFileAdded"
                    @delete-file="handleRemoveFile"
                    @update-file="handleUpdateFile"/>
                </div>
                <div
                  v-for="(unit, unitId) in orderedLessons"
                  :key="unitId"
                  class="flex flex-col items-center gap-4 w-full">
                  <div class="self-baseline text-secondary-975 text-sm">
                    {{ unit.title }}
                  </div>
                  <div
                    v-for="lesson in unit.lessons"
                    :key="lesson.id"
                    class="px-4 gap-2 w-full flex flex-col items-stretch">
                    <div class="text-sm">
                      <span class="text-secondary-975">
                        נושא {{ getIndexOutline(lesson.id) + 1 }} -
                      </span>
                      <span class="text-secondary-900">{{ lesson.title }}</span>
                    </div>
                    <filesUpload
                      :files="lesson.files"
                      :lesson-id="lesson.id"
                      :owner-id="user.id"
                      :readonly="readonly"
                      @file-added="handleFileAdded"
                      @update-file="handleUpdateFile"
                      @delete-file="handleRemoveFile"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollableContainer>
      </div>
    </div>
  </PVDrawer>
</template>
<script setup lang="ts">
import PVDrawer from 'primevue/drawer';
import { Search as SearchIcon } from 'lucide-vue-next';
import { computed, ref, watch, onMounted } from 'vue';
import ScrollableContainer from '/@/views/playground/partials/ScrollableContainer.vue';
import { useI18n } from 'vue-i18n';
import { PropType } from 'vue';
import { useCourseStore } from '/@/app/store/course';
import { useAccountStore } from '/@/app/store/account';
import { OutlineItem } from '/@/app/types/interfaces';
import PVSelect from 'primevue/select';
import { FilesUpload } from '@amit/components';
import FilesNotFound from '/@/assets/FilesNotFound.svg?component';
import noInitialFiles from '/@/assets/noInitialFiles.png';
import { isEmpty } from 'lodash';
import { BaseInput } from '@nwire/amit-design-system';

const courseStore = useCourseStore();
const accountStore = useAccountStore();
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  outlines: {
    type: Array as PropType<OutlineItem[]>,
    required: true,
  },
  units: {
    type: Array as PropType<OutlineItem[]>,
    required: true,
  },
  ownerId: {
    type: String,
    default: '',
  },
  lessonId: {
    type: String,
    default: null,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  showUnPublished: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:visibleFilesCount']);

const { t } = useI18n();
const visible = defineModel<boolean | undefined>();
const search = ref('');

const selectedLesson = ref(
  props.lessonId
    ? {
        id: props.lessonId,
        title: props.outlines.find(l => l.id === props.lessonId)?.title,
      }
    : null,
);

watch(
  () => ({ lessonId: props.lessonId, outlines: props.outlines }),
  ({ lessonId, outlines }) => {
    if (lessonId) {
      selectedLesson.value = lessonId
        ? {
            id: lessonId,
            title: outlines.find(l => l.id === lessonId)?.title,
          }
        : null;
    } else {
      selectedLesson.value = null;
    }
  },
  { deep: true },
);

watch(
  () => props.modelValue,
  newVal => {
    if (!newVal) {
      search.value = '';
    }
  },
);

const storeFiles = computed(() => courseStore.files);

const user = computed(() => accountStore.user);

const courseFiles = computed(() => {
  if (
    props.readonly &&
    !storeFiles.value.filter(file => !file.metadata.lesson).length
  ) {
    return [];
  }

  if (!isEmpty(search.value)) {
    return storeFiles.value
      .filter(file => !file.metadata.lesson)
      .filter(
        file =>
          (file.file.type == 'link' &&
            file.url?.toLowerCase().includes(search.value.toLowerCase())) ||
          file.title?.toLowerCase().includes(search.value.toLowerCase()),
      );
  }
  return storeFiles.value.filter(file => !file.metadata.lesson);
});

const lessonFiles = computed(() => {
  if (!isEmpty(search.value)) {
    return storeFiles.value
      .filter(file => file.metadata.lesson === selectedLesson.value?.id)
      .filter(
        file =>
          (file.file.type === 'link' &&
            file.url?.toLowerCase().includes(search.value.toLowerCase())) ||
          file.title?.toLowerCase().includes(search.value.toLowerCase()),
      );
  }
  return storeFiles.value.filter(
    file => file.metadata.lesson === selectedLesson.value?.id,
  );
});

const getIndexOutline = outlineId => {
  return props.outlines.findIndex(outline => outline.id === outlineId);
};

const orderedLessons = computed(() => {
  const lessons = props.units.reduce((acc, unit) => {
    acc[unit.id] = {
      title: unit.title,
      lessons: props.outlines
        .filter(lesson => {
          return (
            lesson.unit === unit.id &&
            (props.showUnPublished || lesson.published)
          );
        })
        .map(lesson => ({
          ...lesson,
          files: storeFiles.value
            .filter(file => file.metadata.lesson === lesson.id)
            .filter(
              file =>
                (!search.value.length && file.file.type === 'link') ||
                (file.file.type === 'link' &&
                  file.url
                    ?.toLowerCase()
                    .includes(search.value.toLowerCase())) ||
                file.title?.toLowerCase().includes(search.value.toLowerCase()),
            ),
        }))
        .filter(lesson => {
          return props.readonly ? lesson.files.length > 0 : true;
        }),
    };
    return acc;
  }, {});

  // If readonly, filter out units and lessons without files
  if (props.readonly) {
    return Object.entries(lessons).reduce((acc, [unitId, unit]) => {
      // Filter lessons with files
      const lessonsWithFiles = unit.lessons.filter(
        lesson => lesson.files.length > 0,
      );

      // Only include unit if it has lessons with files
      if (lessonsWithFiles.length > 0) {
        acc[unitId] = {
          ...unit,
          lessons: lessonsWithFiles,
        };
      }
      return acc;
    }, {});
  }
  return lessons;
});

const handleRemoveFile = async fileId => {
  await courseStore.deleteFile(fileId);
};

const handleUpdateFile = (file, keyToUnsave?) => {
  courseStore.updateFileInStore(file, keyToUnsave);
};

const lessonOptions = computed(() => {
  return [
    { id: '', title: t('course_page.all_topics') },
    ...props.outlines
      .filter(lesson => props.showUnPublished || lesson.published)
      .map(lesson => ({
        id: lesson.id,
        title: lesson.title,
      })),
  ];
});

const handleFileAdded = (file, addTo = 'top') => {
  courseStore.addFileToStore(file, addTo);
};

const noFilesToDisplay = computed(() => {
  if (selectedLesson.value && !isEmpty(selectedLesson.value.id)) {
    return isEmpty(lessonFiles.value);
  }
  const hasCourseFiles = courseFiles.value.length > 0;
  const hasTopicFiles = !Object.values(orderedLessons.value).every(unit =>
    unit.lessons.every(lesson => lesson.files.length === 0),
  );

  return !hasCourseFiles && !hasTopicFiles;
});

const noFilesAfterSearch = computed(() => {
  return !isEmpty(search.value) && noFilesToDisplay.value;
});

const visibleFilesCount = computed(() => {
  const allFiles = [
    ...courseFiles.value,
    ...Object.values(orderedLessons.value).flatMap(unit =>
      unit.lessons.flatMap(lesson => lesson.files),
    ),
  ];

  return allFiles.length;
});

watch(visibleFilesCount, newValue => {
  emit('update:visibleFilesCount', newValue);
});

onMounted(() => {
  emit('update:visibleFilesCount', visibleFilesCount.value);
});
</script>
