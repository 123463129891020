<template>
  <div class="flex flex-col gap-[0.625rem]">
    <!-- Uploading Files -->
    <div v-if="uploadingFiles.length && !readonly" class="flex flex-col">
      <div
        v-for="file in uploadingFiles"
        :key="file.id"
        class="w-full rounded bg-white border p-3 flex items-center justify-between h-[3.75rem]"
        :class="{
          'border-red-500 text-red-500':
            file.status === 'failed' || file.notSupported,
        }">
        <span v-if="file.notSupported" class="text-base">
          {{ t('course_settings.type_not_supported') }}
        </span>
        <span v-else class="text-base">
          {{
            file.status === 'failed'
              ? t('course_settings.upload_failed')
              : file.name
          }}
        </span>
        <div class="flex items-center justify-center w-8">
          <ProgressSpinner
            v-if="file.status === 'uploading'"
            :style="{ height: '2rem', width: '2rem' }"/>
          <button
            v-else-if="file.status === 'failed' && !file.notSupported"
            class="p-2 rounded-[0.625rem] bg-red-50 hover:bg-red-200 transition-colors hover:text-secondary-975"
            @click="retryUpload(file)">
            <RefreshCcw size="16" />
          </button>
        </div>
      </div>
    </div>

    <!-- Uploaded Files -->
    <div class="flex flex-col gap-[0.625rem]">
      <div v-for="file in files" :key="file.id">
        <LinkRow
          v-if="file.file?.type === 'link'"
          :file="file"
          :readonly="readonly"
          @delete-file="deleteFile"
          @update-file="updateLink"/>
        <div
          v-else
          class="w-full cursor-pointer rounded bg-white border hover:bg-secondary-50 active:bg-secondary-100 border-secondary-300 text-secondary-900 p-3 flex items-center justify-between"
          :class="{ 'bg-secondary-100': selectedFile === file.id }"
          @click.stop.prevent="downloadFile(file)">
          <span class="text-secondary-900 text-sm truncate">
            {{ file.title }}
          </span>
          <div class="flex gap-2">
            <div v-if="file.deleting" class="flex items-center justify-center">
              <ProgressSpinner :style="{ height: '2rem', width: '2rem' }" />
            </div>
            <button
              v-else-if="!readonly"
              class="h-8 w-8 flex items-center justify-center rounded-[0.625rem] bg-red-50 border-1 border-secondary-300 hover:bg-red-100 transition-colors hover:text-secondary-975"
              @click.stop="deleteFile(file)">
              <X size="16" />
            </button>
            <button
              v-else-if="readonly"
              :title="$t('course_settings.download_file')"
              class="h-8 w-8 flex items-center justify-center rounded-[0.625rem] border-1 border-secondary-300 bg-secondary-100 transition-colors hover:text-secondary-975">
              <CloudDownload size="16" class="" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Drop/Browse Area -->
    <div
      v-if="!readonly"
      class="relative border hover:bg-secondary-50 active:bg-secondary-100 border-secondary-300 rounded p-3 flex items-center justify-between cursor-pointer hover:border-secondary-400 transition-colors"
      @dragover.prevent
      @drop.prevent="handleDrop"
      @click="$refs.fileInput.click()">
      <input
        ref="fileInput"
        type="file"
        multiple
        accept="*/*"
        class="hidden"
        @change="handleFileSelect"/>
      <div class="flex items-center justify-between w-full h-full">
        <p class="text-secondary-700 text-base/1.125rem font-normal">
          {{ $t('course_settings.addFile') }}
        </p>
        <div class="flex gap-2">
          <button
            :title="$t('course_settings.add_link')"
            class="h-8 w-8 flex items-center justify-center rounded-[0.625rem] bg-secondary-100 border-1 border-secondary-300 text-secondary-975"
            @click.stop="addLink">
            <Link size="16" />
          </button>
          <button
            :title="$t('course_settings.add_file')"
            class="h-8 w-8 flex items-center justify-center rounded-[0.625rem] bg-secondary-100 border-1 border-secondary-300 text-secondary-975">
            <Plus size="16" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { Plus, X, RefreshCcw, CloudDownload, Link } from 'lucide-vue-next';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import ProgressSpinner from 'primevue/progressspinner';
import { useFirebase } from '@amit/auth';
import { generateObjectId } from '@/utils';
import LinkRow from './LinkRow.vue';

const { t } = useI18n();

async function getToken(forceRefresh = false) {
  const auth = useFirebase();
  return auth.currentUser?.getIdToken(forceRefresh);
}

const props = defineProps({
  files: {
    type: Array,
    required: true,
  },
  lessonId: {
    type: String,
    default: '',
  },
  ownerId: {
    type: String,
    default: '',
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['delete-file', 'file-added', 'update-file']);
const route = useRoute();
const uploadingFiles = ref([]);
const fileInput = ref(null);
const toast = useToast();
const selectedFile = ref('');

const MAX_FILE_SIZE = 100 * 1024 * 1024; // 20MB in bytes

const handleDrop = e => {
  const droppedFiles = [...e.dataTransfer.files];
  uploadFiles(droppedFiles);
};

const handleFileSelect = e => {
  const selectedFiles = [...e.target.files];
  uploadFiles(selectedFiles);
  e.target.value = null; // Reset input
};

const generateRandomId = () => {
  return Array.from(crypto.getRandomValues(new Uint8Array(9)), byte =>
    byte.toString(36),
  )
    .join('')
    .substr(0, 9);
};

const generateLinkObject = () => {
  return {
    id: generateObjectId(),
    new: true,
    url: '',
    title: '',
    file: { type: 'link' },
    metadata: {
      course: route.params.course,
      lesson: props.lessonId,
      owner: props.ownerId,
    },
  };
};
const addLink = () => {
  emit('file-added', generateLinkObject(), 'bottom');
};

const uploadFiles = async files => {
  for (const file of files) {
    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      toast.add({
        severity: 'error',
        summary: t('course_settings.file_too_large', { maxSize: '100MB' }),
        life: 4000,
      });
      continue;
    }

    const uploadingFile = {
      id: generateRandomId(),
      name: file.name,
      fileBlob: file,
      progress: 0,
      status: 'uploading',
    };
    uploadingFiles.value.push(uploadingFile);

    const formData = new FormData();
    formData.append('files', file);

    const metadata = {
      courseId: route.params.course,
      lessonId: props.lessonId || '',
      ownerId: props.ownerId,
    };
    formData.append('metadata', JSON.stringify(metadata));

    try {
      const token = await getToken();
      const response = await fetch(
        `${import.meta.env.VITE_API_BASE}/files/upload`,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: progressEvent => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            const fileIndex = uploadingFiles.value.findIndex(
              f => f.id === uploadingFile.id,
            );
            if (fileIndex !== -1) {
              uploadingFiles.value[fileIndex].progress = progress;
            }
          },
        },
      );

      if (!response.ok) {
        const fileToUpdate = uploadingFiles.value.find(
          f => f.id === uploadingFile.id,
        );
        if (fileToUpdate) {
          fileToUpdate.status = 'failed';
        }
        const errorMessage = await response.text();
        const parsedErrorMessages = JSON.parse(errorMessage);

        if (parsedErrorMessages.message.includes('File type not allowed')) {
          fileToUpdate.notSupported = true;
        }

        throw new Error('Upload failed');
      }

      const data = await response.json();
      emit('file-added', data.data);
      uploadingFiles.value = uploadingFiles.value.filter(
        f => f.id !== uploadingFile.id,
      );
    } catch (error) {
      console.log(error);
      console.error('Upload error:', error);
    }
  }
};

const retryUpload = file => {
  uploadingFiles.value = uploadingFiles.value.filter(f => f.id !== file.id);
  uploadFiles([file.fileBlob]);
};

const deleteFile = file => {
  file.deleting = true;
  emit('delete-file', file.id);
};

const updateLink = (file, keyToUnsave) => {
  emit('update-file', file, keyToUnsave);
};

const downloadFile = file => {
  selectedFile.value = file.id;
  const link = document.createElement('a');
  const fileName =
    file.title || decodeURIComponent(file.url.split('/').pop().split('?')[0]);
  link.href = file.url;
  link.download = fileName;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
</script>
